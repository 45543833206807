import ky from 'ky';
import { authHeaderHook, buildVersionHook, throwErrorMessageAndCodeHook } from './hooks';

const timeout = 30 * 1000;

const kyWithCustomer = ky.create({
  timeout,
  hooks: {
    beforeRequest: [authHeaderHook],
    afterResponse: [buildVersionHook, throwErrorMessageAndCodeHook]
  }
});

export default kyWithCustomer;
